/* eslint-disable react/no-unescaped-entities */
import { m } from 'framer-motion';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Grid, Button, Container, Typography, LinearProgress } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// utils
import { fPercent } from '../../utils/formatNumber';
// _mock_
import { _skills } from '../../_mock/arrays';
// components
import Image from '../../components/image';
import Iconify from '../../components/iconify';
import { MotionViewport, varFade } from '../../components/animate';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(20),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
  },
}));

// ----------------------------------------------------------------------

export default function KidsGamePrivacyPolicyContent() {
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const isLight = theme.palette.mode === 'light';

  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.48
  )}`;

  return (
    <StyledRoot>
      <Container component={MotionViewport}>
        <Box display="flex" flexDirection="column">
          <Typography variant="h3" sx={{ mb: 3 }}>
            KIDS GAMES PRIVACY POLICY
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            Your privacy is important to Rhizo and we are committed to protecting the privacy of
            parents and kids. Please take a moment to familiarize yourself with our privacy
            practices.
            <br />
            <br />
            Rhizo uses and makes all attempts to protect all information when you use our apps &
            services.
            <br />
            <br />
            If you do not agree to this Privacy Policy, please do not use the service. We reserve
            the right to make changes to this Privacy Policy. If we make a material change to this
            Privacy Policy, we will notify you by posting the change in this Privacy Policy. Please
            check back from time to time to ensure that you are aware of these changes. Your
            continued use of the Service will signify your acceptance of these changes.
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            Rhizo operates the https://www.rhizo.cloud website and the specialized in the publishing
            of mobile games and applications (hereinafter referred to as the &quot;Service&quot;).
            <br />
            <br />
            This page informs you of our policies regarding the collection, use and disclosure of
            data when you use our Service and the choices you have associated with that data.
            <br />
            <br />
            If you have questions, comments, or concerns regarding privacy and data protection in
            general, please don&apos;t hesitate to contact Rhizo Data Protection Officer as
            indicated in the contact section of this policy.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Personal Data Collected:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            We use technologies like cookies on our website (small files stored by your browser or
            operating system), web beacons, or unique device identifiers to anonymously identify
            your computer or device. Our systems also logs information like your browser, operating
            system, IP address and anonymous usage data so we can deliver a better experience. We do
            not sell, trade, or rent Users personal identification information to others.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Non-personal Data:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            For purposes of this Privacy Policy, "non-personal data" means information that does not
            directly identify you. Additionally, non-personal data means "aggregate" and
            "de-personalized" information, which is data Rhizo collects about the use of RV
            AppStudios users, from which any personally identifiable data has been removed.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            App Store and Data Collection:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            We currently do not serve any third party advertisements, except for our own games on a
            limited basis to parents from time to time.
            <br />
            <br />
            We make the toddler focused games available via app stores such as Apple’s App Store,
            Google Play, Amazon AppStore, and Windows Store’s. While using the game, the App Store
            may use cookies, unique device identifier codes, software and hardware information,
            browser information, time zone and usage information or other technology to collect and
            process the information about you, and we may have access to it. We have no control over
            an App Store’s collection or use of information and cannot change what information is
            made available to us by the App Store.
            <br />
            <br />
            If you do not want to be subject to these technologies, do not use or access our
            Services.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Information Disclosure & Security:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            In the following circumstances, we may disclose your personal information according to
            your wish or regulations by law:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>Your prior permission.</Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            By the applicable law within or outside your country of residence, legal process,
            litigation requests.
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            By requests from public and governmental authorities.
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            To protect our legal rights and interests. We take reasonable steps to secure your
            information against unauthorized access or disclosure. We encrypt transmission of data
            on pages where you provide payment information. However, no security or encryption
            method can be guaranteed to protect information from hackers or human error.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Contacting Us:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            If you have any questions regarding the Privacy Policy, please contact us at the
            following email address. support@rhizo.cloud
          </Typography>
        </Box>
      </Container>
    </StyledRoot>
  );
}

// ----------------------------------------------------------------------

type ProgressItemProps = {
  progress: {
    label: string;
    value: number;
  };
};

function ProgressItem({ progress }: ProgressItemProps) {
  const { label, value } = progress;
  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ mb: 1.5, display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle2">{label}&nbsp;-&nbsp;</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 3 }}>
          {fPercent(value)}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          '& .MuiLinearProgress-bar': { bgcolor: 'grey.700' },
          '&.MuiLinearProgress-determinate': { bgcolor: 'divider' },
        }}
      />
    </Box>
  );
}
