/* eslint-disable react/no-unescaped-entities */
import { m } from 'framer-motion';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Grid, Button, Container, Typography, LinearProgress } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// utils
import { fPercent } from '../../utils/formatNumber';
// _mock_
import { _skills } from '../../_mock/arrays';
// components
import Image from '../../components/image';
import Iconify from '../../components/iconify';
import { MotionViewport, varFade } from '../../components/animate';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(20),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
  },
}));

// ----------------------------------------------------------------------

export default function TermsOfServiceContent() {
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const isLight = theme.palette.mode === 'light';

  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.48
  )}`;

  return (
    <StyledRoot>
      <Container component={MotionViewport}>
        <Box display="flex" flexDirection="column">
          <Typography variant="h3" sx={{ mb: 3 }}>
            TERMS OF SERVICE
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            Effective Date: 28th October, 2023
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Introduction:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            These Terms of Service (Terms) govern your access to and use of our services including
            games, applications, websites and other services. Please read them carefully. By using
            the Services you agree to be bound by these Terms. If you accept these terms, you
            represent that you are age 13 or older. If you are between the ages of 13 and 18, then
            you represent that your parents or your legal guardian has reviewed and agreed to these
            Terms of Services.
            <br />
            <br />
            These terms are made available via the app store or platform that you download our Games
            such as the Apple App Store, Google Play Store, Amazon App Store, Windows Phone Store,
            Facebook or any related services related to our games. Your right to use the Services is
            subject to such relevant terms and policy.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Access or Use of the Services:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            You agree to comply with the specific rules for each Game that you access or play. Those
            rules concern matters such as how to score points, how to control characters, what to do
            with resources etc. When made available, information about gameplay, features and
            functions inside our Games can be found in the Game descriptions on the app stores and
            platforms and on our website.
            <br />
            <br />
            You understand that downloading or connecting to and playing our Games requires an
            Internet connection that you are responsible for. Your Internet service provider may
            charge you for gaining access to the Internet.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Purchases in the Services:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            Our games are free to play, it contains in-app purchases and advertising. Our Games may
            include virtual currencies such as coin and gems (Virtual Money) or items or services
            for use with our Games (Virtual Goods). The balance of virtual goods or virtual currency
            does not contain real value. In-app purchases can be purchased with real money and you
            can turn off the payment feature in your device settings. Price and availability of
            Virtual Items are subject to change without notice.
            <br />
            <br />
            If you choose to sign up for a subscription services, you can get exclusive offers and
            tons of free extras. VIP subscription is available on a weekly/monthly/yearly tier,
            which auto-renews. Payments will be charged to the user's Account at confirmation.
            Subscriptions automatically renew unless canceled at least 24 hours before the end of
            the billing cycle. Continuing subscribers will be charged weekly/monthly/yearly for
            renewal. Manage auto-renewal settings and payment methods by going to Account Settings
            on the device. No cancellation of the current subscription is allowed during the active
            subscription period. Unused portions of free trials will be forfeited upon purchase of a
            subscription.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Code of Conduct:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            You shall download and connect to and play our Games only for your personal use and
            according to these Terms of Service, any app store and platforms terms and applicable
            law.
            <br />
            <br />
            You shall only download and play our Games and acquire Virtual Items from the app stores
            and platforms that we make our Games available on, and only play our Games on devices
            and platforms that we provide the relevant Game for.
            <br />
            <br />
            You shall not interfere with our Games and gameplay inside our Games irrespective of
            whether or not this includes manipulation of the software, backend or networks. In
            particular and without limitation you shall not: create, offer or use hacks, cheats,
            exploits or any other unauthorized application, tool or command that modifies the Game
            or gameplay or circumvents technical protection measures in a way not intended by us.
            <br />
            <br />
            If provision of information or data is required, you must provide all of these
            accurately and in full, and keep them up-to-date at all times.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Third-Party Services and Links:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            We may link to third party websites or services from our Services. Your use of the
            Service may also include the use of applications that are developed or owned by a third
            party. Your use of such third party applications, websites, and services is governed by
            that party’s own terms of service or privacy policies. We encourage you to read the
            terms and conditions and privacy policy of any third party application, website or
            service that you visit or use.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Account Information:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            You may need to create an account to use some of our Services. When using the services,
            you are responsible for maintaining the confidentiality of your account and password,
            and you agree to accept responsibility for all activities that occur under your account
            or password.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Playing Games With Other Users:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            Some of our Games allow you to play against an opponent or to play socially with other
            users. You may be able to choose to play against another user or to play socially with
            another user whom we selects for you. By accessing and/or playing our Games you agree
            that your display name, scores, avatar, country location, online/offline status and
            other related details may be displayed in any and all media (whether it exists now or in
            the future), for any purpose, in perpetuity, without any payment to you, including
            (without limitation) to other users in our games or within our marketing. You also
            understand and agree that other users may find you by searching for you with your email
            address. Please note that we will only show your display name publically, and not your
            email address; another user must already know your email address themselves in order to
            search for you.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Binding Arbitration / Class Waiver:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            These provision regarding binding arbitration and class action apply to you if you are a
            resident in the United States of America or access, download and use our Games and other
            Services in the United States of America. You and us agree that there is no right or
            authority for any dispute to be arbitrated on a class-action basis or to utilize class
            action procedures, there is no right or authority for any dispute to be brought in a
            purported representative capacity or as a private attorney general, and no arbitration
            shall be joined with any other arbitration. Changes addressing new functions or made for
            legal reasons, we will notify you 30 days in advance of making effective changes to
            these terms that impact the rights or obligations of any party to these terms.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Suspension and Termination of Services:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            We may suspend or terminate your account or cease providing you with all or part of the
            Services at any time for any or no reason, including, but not limited to, if we
            reasonably believe: (i) delete, suspend and/or modify your Account or parts of your
            Account; (ii) limit, suspend or terminate access to the Services; (iii) modify or remove
            any Virtual Money or Items; (iv) reset and/or modify any game progression or benefits
            and privileges associated with you, such as any level or score you have reached in the
            Games.)
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Disclaimer:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            The Services are provided to you are sold "AS IS" and without warranties as to
            performance or merchantability or any other warranties whether expressed or implied. It
            may have defects, and your use is solely at your risk.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Limitation of Liability:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            We shall not be liable for liable for damages, direct or consequential, resulting from
            your use of the Service, and you agree to defend, indemnify and hold us harmless from
            any claims, losses, liability costs and expenses, including but not limited to
            attorney's fees, arising from your violation of any third-party's rights.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Copyright Restriction:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            We put a lot of effort into creating the Service including, the logo and all designs,
            text, graphics, pictures, information and other content (excluding your content). This
            property is owned by us or our licensors and it is protected by international copyright,
            trademark, patent, trade secret and other intellectual property or proprietary rights
            laws. You shall not attempt to modify, reverse engineer, disassemble or decompile our
            games. Nor can you create any derivative works or other works that are based upon or
            derived from our games in whole or in part.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Changes to Terms or Services:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            We reserves the right to amend, change, modify, add or remove portions of the Terms of
            Services and Privacy Policy at any time, at its sole discretion, by posting the updated
            version on its website and within the games. We may do so for a number of reasons
            including without limitation because we change the nature of our products or services,
            for technical or legal reasons, or because the needs of our business have changed. You
            agree that if you do not accept any amendment to our terms then you shall immediately
            stop accessing and/or using our Services.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Governing Law:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            This Agreement shall be governed by the law of the U.S. applicable therein. You hereby
            irrevocably attorn and submit to the non-exclusive jurisdiction of the courts of U.S.
            therefrom. If any provision shall be considered unlawful, void or otherwise
            unenforceable, then that provision shall be deemed severable from this License and not
            affect the validity and enforceability of any other provisions.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Assignment and Severability:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            We may transfer all or a part of its rights or obligations pursuant to this User
            Agreement to a third party without your consent. You may transfer any of your rights or
            obligations under this User Agreement only with our prior written approval.
            <br />
            <br />
            If any provision of these terms is held invalid or unenforceable for any reason, then
            that provision shall be deemed to be severable from the conditions and shall not affect
            the validity and enforceability of any of the remaining provisions of the conditions.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Entire Agreement:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            These Terms constitute the entire agreement between you and us concerning the use of the
            Services and prior agreements between you and us relating to your use of the Services.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Contact Details:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            If you have any questions regarding these terms of services, please contact us at the
            following email address: support@rhizo.cloud
          </Typography>
        </Box>
      </Container>
    </StyledRoot>
  );
}

// ----------------------------------------------------------------------

type ProgressItemProps = {
  progress: {
    label: string;
    value: number;
  };
};

function ProgressItem({ progress }: ProgressItemProps) {
  const { label, value } = progress;
  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ mb: 1.5, display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle2">{label}&nbsp;-&nbsp;</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 3 }}>
          {fPercent(value)}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          '& .MuiLinearProgress-bar': { bgcolor: 'grey.700' },
          '&.MuiLinearProgress-determinate': { bgcolor: 'divider' },
        }}
      />
    </Box>
  );
}
