import { Helmet } from 'react-helmet-async';
// @mui
import { Divider } from '@mui/material';
// sections
import KidsGamePrivacyPolicyContent from 'src/sections/privacy/KidsGamePrivacyPolicyContent';

// ----------------------------------------------------------------------

export default function KidsGamePrivacyPolicyPage() {
  return (
    <>
      <Helmet>
        {/* <title> About us | Rhizo</title> */}
        <title> Kids Game Privacy Policy | Rhizo</title>
      </Helmet>

      <KidsGamePrivacyPolicyContent />
    </>
  );
}
