/* eslint-disable react/no-unescaped-entities */
import { m } from 'framer-motion';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Grid, Button, Container, Typography, LinearProgress } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// utils
import { fPercent } from '../../utils/formatNumber';
// _mock_
import { _skills } from '../../_mock/arrays';
// components
import Image from '../../components/image';
import Iconify from '../../components/iconify';
import { MotionViewport, varFade } from '../../components/animate';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(20),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
  },
}));

// ----------------------------------------------------------------------

export default function PrivacyPolicyContent() {
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const isLight = theme.palette.mode === 'light';

  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.48
  )}`;

  return (
    <StyledRoot>
      <Container component={MotionViewport}>
        <Box display="flex" flexDirection="column">
          <Typography variant="h3" sx={{ mb: 3 }}>
            PRIVACY POLICY
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            Last amended on 28 of October 2023
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            Rhizo operates the https://www.rhizo.cloud website and the specialized in
            the publishing of mobile games and applications (hereinafter referred to as the
            &quot;Service&quot;).
            <br />
            <br />
            This page informs you of our policies regarding the collection, use and disclosure of
            data when you use our Service and the choices you have associated with that data.
            <br />
            <br />
            If you have questions, comments, or concerns regarding privacy and data protection in
            general, please don&apos;t hesitate to contact Rhizo Data Protection Officer as
            indicated in the contact section of this policy.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Information Collection and Use:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            When you install and use Rhizo applications, we collect several different types
            of information for various purposes to provide and improve our Service to you.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            We May Collect Data:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - To provide you with the services you asked for Example: saving your game progress or
            applications settings
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - Improve, optimize and personalize our Services and our users experiences either
            directly or through third party partners Example: identifying that a feature is annoying
            for users or that a level is too difficult for most players
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - Display third party tailored advertisements based on the interests of our users
            Example: Advertisements that are tailored for your interests may include banner ads on
            game boards or in Services, such as full-screen ads between moves or level change.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            Rhizo collects user data for the purpose of its own internal analytics tools.
            Data is used for the sole purpose of an analytics tool and is not shared with any third
            parties. For all data processing activities that rely on users consent, you can withdraw
            your consent at any time from the &quot;settings&quot; page in our various applications.
            <br />
            <br />
            You may still see the same number of ads even after you withdraw your consent. However,
            these ads may not be as relevant and as per your interest like, before withdrawing
            consent. Our third-party advertising partners may continue showing you ads.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            The Data We May Collect:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            In order to give you the best experience in the use of the Service, Rhizo may
            collect some information.We never sell your info to any 3rd party and make a good faith
            effort to protect your data.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>- The apps you are using</Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - A unique identifier (Player ID which we generate from the game)
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - Other contextual data about your apps or games (like levels, saved settings)
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - Information about the device (type of connection, timestamp, system language, device
            model, available storage space, time zone, OS)
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Who We Share Your Data With & Why?
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            We never sell and share your info to any 3rd party and make a good faith effort to
            protect your data. In the scenario when the personal information is held by a
            third-party data controller, such as a payment processor or an advertising partner, we
            advise you to directly contact them as per their own privacy policies. We are using the
            following advertising networks and analytics tools.
          </Typography>

          <Typography variant="h6" sx={{ mb: 3 }}>
            Advertising Network:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            <div style={{ lineHeight: '21px' }}>
              <div>
                <span>AdMob Network: </span>
                <span>
                  {' '}
                  <a
                    href="https://policies.google.com/technologies/partner-sites"
                    style={{ color: '#38B9EC' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    https://policies.google.com/technologies/partner-sites
                  </a>{' '}
                </span>
              </div>
            </div>
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Analytic Companies:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            To monitor and analyze the use and performance of our services, we may take help of some
            third-party service providers.
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            <div style={{ lineHeight: '21px' }}>
              <div>
                <span>Google Analytics: </span>
                <span>
                  {' '}
                  <a
                    href="https://policies.google.com/privacy"
                    style={{ color: '#38B9EC' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    https://policies.google.com/privacy
                  </a>{' '}
                </span>
              </div>
            </div>
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            <div style={{ lineHeight: '21px' }}>
              <div>
                <span>Firebase: </span>
                <span>
                  {' '}
                  <a
                    href="https://policies.google.com/privacy"
                    style={{ color: '#38B9EC' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    https://policies.google.com/privacy
                  </a>{' '}
                </span>
              </div>
            </div>
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Payments:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            <div style={{ lineHeight: '21px' }}>
              <div>
                <span>Apple Store In-App Payments: </span>
                <span>
                  {' '}
                  <a
                    href="https://www.apple.com/legal/privacy/"
                    style={{ color: '#38B9EC' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    https://www.apple.com/legal/privacy/
                  </a>{' '}
                </span>
              </div>
            </div>
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            <div style={{ lineHeight: '21px' }}>
              <div>
                <span>Google Play In-App Payments: </span>
                <span>
                  {' '}
                  <a
                    href="https://www.google.com/policies/privacy/"
                    style={{ color: '#38B9EC' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    https://www.google.com/policies/privacy/
                  </a>{' '}
                </span>
              </div>
            </div>
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Social Network:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            <div style={{ lineHeight: '21px' }}>
              <div>
                <span>Google: </span>
                <span>
                  {' '}
                  <a
                    href="https://policies.google.com/privacy/update"
                    style={{ color: '#38B9EC' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    https://policies.google.com/privacy/update
                  </a>{' '}
                </span>
              </div>
            </div>
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            <div style={{ lineHeight: '21px' }}>
              <div>
                <span>Facebook: </span>
                <span>
                  {' '}
                  <a
                    href="https://www.facebook.com/policy.php"
                    style={{ color: '#38B9EC' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    https://www.facebook.com/policy.php
                  </a>{' '}
                </span>
              </div>
            </div>
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Purpose of Personal Data:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            We will use the information you provide to create a profile of your interests so we can
            offer events & services based on your preferences. We would like to process your
            concerns and provide you with services and information, improve the layout and content
            of the pages of the App and customize them.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - To provide, operate, support, and monitor the usage and performance of our services
            and develop our games and services.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - To enhance user experience, customized gaming experience and improve our products,
            services
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - To create accounts and allow users to use our services and products with ease.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - To serve advertisements and measure its effectiveness for users.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - To conduct and enable online challenges and populate online leaderboards.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - To help you find your friends and tell them about our apps and games.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - To support gameplay on multiple devices as and when required.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - To give updates about our applications and games.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - To save game progress and data related to stats.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - To recognize and troubleshoot difficulties with your games or account.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - Survey your opinions through reviews and inquiries.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - To send you confirmation and necessary information about the products and services
            that you are using.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - To contact you by email, or push notifications to inform you regarding promotions,
            rewards, upcoming events, service, or security updates when required or necessary.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - To answer and handle the questions and requests that you have sent to us.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - Disclose to legal and lawful authorities if required to do so by law.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Storage, Security and Sharing:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            Personal data collected, received and processed for the purposes described in this
            policy is not kept longer than necessary by Rhizo. We never sell or share data
            to any third party services.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            How Long We Keep Your Data
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            We will only retain your personal information for as long as necessary to fulfill the
            purposes for which it was collected, or as necessary to comply with our business
            requirements and applicable laws; to comply with any legal, accounting or reporting
            obligations; to resolve disputes; to protect our assets; to efficiently run our
            business; and to enforce our agreements.
            <br />
            <br />
            The only personally identifiable information we collect is when someone emails us for
            support through the app. We remove the email address from support every 90 days or after
            the purpose for which they were collected has been achieved. We do not collect any
            information directly. While you may contact us, we encourage you to contact our third
            party providers listed in the privacy policy. If you have any questions or concerns
            please do contact us at the email provided.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Children&apos;s Privacy
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            Our services are not directed to children and we don't direct them to anyone under 13.
            We abide by the Children's Online Privacy Protection Act ("COPPA"). If a user gets
            identified as a child under this prescribed age within the Services, Rhizo will
            not collect, store or use any information of such user. Rhizo also does not
            allow third party partners to access personally identifiable data from users under 13.
            Also, we may limit how we collect, use, and store some of the information of EU users
            between 13 and 16.
            <br />
            <br />
            Moreover, under any prior circumstances, Rhizo received personal information and
            discovered that it was provided by a child under the age of 13, Rhizo will
            promptly delete such personal information in a secure manner.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Transfer of Data
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            We do not sell, trade, or rent users personal identification information to others. Some
            of the partners and processors referred to in this policy are located outside of the
            European Union. We advise you to contact the third party data controller (an advertising
            partner or a payment processor) directly as per their privacy policies.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Your Rights Under GDPR Policy
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            You are at any time entitled to be informed of the personal data about you that we
            process, but with certain legislative exceptions. You also have the right to oppose the
            collection and processing of your data including profiling or decision-making.
            <br />
            <br />
            Furthermore, you have the right to have your data rectified, edited, erased or blocked.
            Moreover, you have the right to receive information about you that you have provided to
            us. If you want us to update, amend or delete the personal data that we have recorded
            about you, wish to get access to the data being processed about you, or if you have any
            questions concerning the above guidelines, you may contact us.
          </Typography>

          <Typography variant="h6" sx={{ mb: 3 }}>
            1. Right To Request:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            You have the right to request access into the data that we are processing on you,
            including information about:
            <br />
            <br />
            the purposes of the processing, categories of personal data concerned are used. the
            recipients to whom the personal data will be disclosed.
            <br />
            <br />
            the envisaged period for which the personal data will be stored. Furthermore, you have
            the right to obtain a copy of the personal data undergoing processing. Please note that
            the access may be restricted due to intellectual property or trade secrets.
          </Typography>

          <Typography variant="h6" sx={{ mb: 3 }}>
            2. Right To Object:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            You have the right to object to your personal data processing on grounds relating to
            your particular situation.
            <br />
            <br />
            In this case, we will cease the processing unless there are compelling legitimate
            grounds for the processing which override your interests, rights and freedoms or if the
            processing is necessary for the establishment, exercise or defense of legal claims.
            <br />
            <br />
            You have the right to object to the process of your personal data for direct marketing.
            We will cease the processing of data for this purpose after objection. Please note that
            if you exercise this right, your user license to use the App will cease automatically.
          </Typography>

          <Typography variant="h6" sx={{ mb: 3 }}>
            3. Right To Rectification And Erasure:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            You have the right to have inaccurate personal data rectified and erased. Furthermore,
            you can have your personal data where one of the following grounds applies:
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - the personal data are no longer necessary in relation to the purposes for which they
            were collected or otherwise processed.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - if you have withdrawn your consent, or objected to the processing and there are no
            legitimate grounds
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - the personal data have to be erased for compliance with a legal obligation, or it has
            been unlawfully processed.
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            - the personal data have been collected in relation to the offer of information society
            services.
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            If you want to review or delete personal information associated with your game account
            data, please submit a request through support@rhizo.cloud
            <br />
            <br />
            Please note that your right to erasure may be limited if the data are necessary for
            compliance with a legal obligation or for the defense of legal claims.
          </Typography>

          <Typography variant="h6" sx={{ mb: 3 }}>
            4. Right To Withdraw Consent:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            If we have asked for your consent to our processing of your data, you have the right to
            withdraw your consent at any time.
            <br />
            <br />
            If you withdraw your consent, we will cease processing of the data, unless there is any
            legal obligation to keep parts of your data. The withdrawal of your consent does not
            affect the lawfulness of processing based on your consent before its withdrawal.
            <br />
            <br />
            You can send us an email at support@rhizo.cloud, to exercise your rights. We
            advise you to contact the third party data controller (an advertising partner or a
            payment processor) directly as per their privacy policies to get your personal
            information deleted when your information is with them. We respond to your request
            within 30 days. However, we may keep some information necessary for completing
            transactions that you have started before making a request to delete or update your
            personal information.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Your Rights Under CCPA Policy
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            If you’re a California resident, you have certain privacy rights under California law,
            including the California Consumer Privacy Act of 2018 (“CCPA”). Our California Privacy
            Notice specifies these rights:
          </Typography>

          <Typography variant="h6" sx={{ mb: 3 }}>
            1. Right to Know
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            You can request to know what all personal information we have collected, processed, and
            used about you. Before processing your request to know, we will confirm that it is you
            who is making this request.
          </Typography>

          <Typography variant="h6" sx={{ mb: 3 }}>
            2. Right to Delete
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            You can request us to delete personal information about you that we have collected at
            any time. However, we may ask you to verify your identity to know that it is actually
            you who is requesting to delete your personal data before processing your request.
          </Typography>

          <Typography variant="h6" sx={{ mb: 3 }}>
            3. Right to Opt Out of Sale
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            You get the right to tell a business that sells your personal information to a third
            party to stop selling it and to also not sell it in the future.
          </Typography>

          <Typography variant="h6" sx={{ mb: 3 }}>
            4. Right to Non-discrimination
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            You also have the right under CCPA to not to be discriminated against for exercising the
            rights mentioned above.
            <br />
            <br />
            We do not sell your info to any 3rd party and make a good faith effort to protect your
            data. We do not respond to Do Not Track signals. Some third party websites may keep
            track of your browsing activities. And, if you are going to those websites, you can
            choose your preference in your browser to be tracked or not tracked. Enable or disable
            DNT through the Settings Page of your browser.
            <br />
            <br />
            If you want to review or delete personal information associated with your game account
            data, please submit a request via support@rhizo.cloud. In the scenario when
            the personal information is held by a third-party data controller, such as a payment
            processor or an advertising partner, we advise you to directly contact them as per their
            own privacy policies to make sure your personal information is deleted. We respond to
            your request immediately, but in any event within the legal timeframe 45 days for more
            CCPA). We may need to keep some information to finish transactions that you have started
            before requesting to delete or update the information.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Changes To This Policy:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            We update our Privacy Policy from time to time. And, after updating it we notify you
            regarding the changes in the Privacy Policy by putting a notice on our company’s website
            or in our products. We request you to go through our Privacy Policy periodically to know
            about the changes in our current privacy practices.
          </Typography>

          <Typography variant="h4" sx={{ mb: 3 }}>
            Contact Details:
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 3 }}>
            If you have any questions regarding the Privacy Policy, please contact us at the
            following email address: support@rhizo.cloud
          </Typography>
        </Box>
      </Container>
    </StyledRoot>
  );
}

// ----------------------------------------------------------------------

type ProgressItemProps = {
  progress: {
    label: string;
    value: number;
  };
};

function ProgressItem({ progress }: ProgressItemProps) {
  const { label, value } = progress;
  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ mb: 1.5, display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle2">{label}&nbsp;-&nbsp;</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 3 }}>
          {fPercent(value)}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          '& .MuiLinearProgress-bar': { bgcolor: 'grey.700' },
          '&.MuiLinearProgress-determinate': { bgcolor: 'divider' },
        }}
      />
    </Box>
  );
}
