import { Helmet } from 'react-helmet-async';
// @mui
import { Divider } from '@mui/material';
// sections
import PrivacyPolicyContent from 'src/sections/privacy/PrivacyPolicyContent';

// ----------------------------------------------------------------------

export default function PrivacyPolicyPage() {
  return (
    <>
      <Helmet>
        {/* <title> About us | Rhizo</title> */}
        <title> Privacy Policy | Rhizo</title>
      </Helmet>

      <PrivacyPolicyContent />
    </>
  );
}
